import Vue from 'vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'
import titleMixin from './mixin/titleMixin';
import VuewAwesomeSwiper from 'vue-awesome-swiper';
import firebase from "firebase/app";
import "firebase/analytics";


import App from './App.vue'
Vue.config.productionTip = false
Vue.mixin(titleMixin);

// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBLOh-jl76E4texRl92yaFC2DaDGwcqawU",
  authDomain: "tenfactor.firebaseapp.com",
  databaseURL: "https://tenfactor-default-rtdb.firebaseio.com",
  projectId: "tenfactor",
  storageBucket: "tenfactor.appspot.com",
  messagingSenderId: "510335629282",
  appId: "1:510335629282:web:0d47ddd832e64dc7e82bbb",
  measurementId: "G-1GJQHZQNNH"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.prototype.$analytics = firebase.analytics();


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
