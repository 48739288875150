<template>
  <div id="app" class="main-page-wrapper">
    <header class="theme-main-header">
      <MainHeader />
      <Nav />
    </header>
    <router-view/>
    <Footer />    
  </div>
</template>

<script>

import MainHeader from '@/views/partials/MainHeader';
import Nav from '@/views/partials/Nav';
import Footer from '@/views/partials/Footer';
  
export default {
    name: 'App',
    computed: {
    },
    components:{
	MainHeader, Nav, Footer
    },
    methods: {
	init() {
	}
    },
    mounted(){
	this.init();
	this.$analytics.logEvent("Load App");
    }
};
</script>
