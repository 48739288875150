import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
console.log("loaded router");

const routes = [
    {
	path: '/',
	name: 'Home',
	component: () => import(/* webpackChunkName: "services" */ '../views/Home.vue')
    },
    {
	path: '/services',
	name: 'Services',
	component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue')
    },
    {
	path: '/expertise',
	name: 'Expertise',
	component: () => import(/* webpackChunkName: "expertise" */ '../views/Expertise.vue')
    },
    {
	path: '/health',
	name: 'Health',
	component: () => import(/* webpackChunkName: "Health" */ '../views/partials/Health.vue')
    },
    {
	path: '/edtech',
	name: 'EdTech',
	component: () => import(/* webpackChunkName: "EdTech" */ '../views/partials/EdTech.vue')
    },
    {
	path: '/martech',
	name: 'Martech',
	component: () => import(/* webpackChunkName: "Martech" */ '../views/partials/Martech.vue')
    },
    {
	path: '/robotics',
	name: 'Robotics',
	component: () => import(/* webpackChunkName: "Robotics" */ '../views/partials/Robotics.vue')
    },
    {
	path: '/enterprise',
	name: 'Enterprise',
	component: () => import(/* webpackChunkName: "Enterprise" */ '../views/partials/Enterprise.vue')
    },
    {
	path: '/about',
	name: 'about',
	component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
	path: '/contact',
	name: 'Contact',
	component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
    },
    {
	path: '/privacy',
	name: 'privacy',
	component: () => import(/* webpackChunkName: "Privacy" */ '../views/termsOfUse.vue')
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
