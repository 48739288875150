<template>
	<footer class="theme-footer">
	  <div class="container">
	    <div class="content-wrapper">

	      <div class="copyright-wrapper row" style="padding: 30px 0 10px 0 !important;">

		<div class="col-md-4 col-sm-4 col-12">
		  <p>Copyright &copy; 2023 Ten Factor Growth</p>
		</div>

		<div class="col-md-8 col-sm-8 col-12">

		  <ul>
		    <li><a style="background: transparent;width: auto; height: auto;" href="/privacy">
			Privacy
			Policy - </a></li>
		    <li><a style="background: transparent;width: auto; height: auto;"
			   href="mailto:info@tenfactorgrowth.com" class="mail-address">Email:
			brian@tenfactorgrowth.com - </a></li>
		    <li><a style="background: #2382c3; width: 30px; height: auto;"
			   href="https://www.linkedin.com/in/brianeberman/" target="_blank"><i
											      class="fa fa-linkedin" aria-hidden="true"></i></a></li>
		  </ul>
		</div>

	      </div> <!-- /.copyright-wrapper -->
	    </div>
	  </div> <!-- /.container -->
	</footer> <!-- /.theme-footer -->
</template>


<script>
export default {
    name: 'Footer',
    components: {
    },
    methods: {
    }
}
</script>

<style scoped>

</style>

