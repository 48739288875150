<template>
  <div id="Nav">
    <div class="main-menu-wrapper clearfix">
      <div class="container clearfix">
      <!-- Logo -->
      <div class="logo float-left">
	<a href="/">
	  <img src="images/mylogo.png" alt="Logo">
	</a>
      </div>
      <!-- ============================ Theme Menu ========================= -->
      <nav class="navbar-expand-lg float-right navbar-light" id="mega-menu-wrapper">
	  <button class="navbar-toggler float-right clearfix"
		  type="button" data-toggle="collapse"
		  data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
		  aria-label="Toggle navigation">
	    <i class="flaticon-menu-options"></i>
	  </button>
	  <div class="collapse navbar-collapse clearfix top-bar" id="navbarNav">
	    <ul class="navbar-nav nav" id="navbarNavList">
	      <li class="nav-item">
		<router-link to="/" class="nav-link"> Home</router-link>
	      </li>

	      <li class="nav-item">
		<router-link to="/about" class="nav-link"> About </router-link>
	      </li>

	      <li class="nav-item">
		<router-link to="/services" class="nav-link"> Services </router-link>
	      </li>

	      <li class="nav-item dropdown-holder" @click="toggle" >
		<a class="nav-link" href="#" ref="dropdownMenu"> Customers</a>
		<div v-if="visible" class="sub-menu" style="display: block">
		  <ul >
		    <li v-for="customer in customers" @click="toggle">
		      <router-link :to="customer['target']">
			{{ customer['name']}}
		      </router-link>
		    </li>
		  </ul>
		</div>
	      </li>
	      <li class="nav-item dot-fix">
		<router-link to="/contact" class="nav-link"> Contact</router-link>
	      </li>
	    </ul>
	  </div>
	</nav>
      </div> <!-- /.container -->
    </div> <!-- /.main-menu-wrapper -->
  </div>
</template>

<script>
export default {
    name: 'Nav',
    components: {
    },
    methods: {
	documentClick(){

	},
	toggle(e){
	    let el = this.$refs.dropdownMenu;
	    let target = e.target;
	    console.log(target);
	    if (el.isSameNode(target)){
		console.log('target node');
		this.visible=true;
	    }else{
		this.visible = !this.visible;
	    }
	    console.log(this.visible);
	}
    },
    data: function () {
	return {
	    customers: [
		{
		    name: 'Health Tech',
		    target: '/health'
		},
		{
		    name: 'Education Tech',
		    target: '/edtech'
		},
		{
		    name: 'Marketing Tech',
		    target: '/martech'
		},
		{
		    name: 'Robotics',
		    target: '/robotics'
		},
		{
		    name: 'Enterprise',
		    target: '/enterprise'
		}
	    ],
	    visible: false
	}
    },
    created() {
    },
    destroyed() {
    }
}
</script>

<style scoped>
/*================= Sticky Menu ===============*/
@-webkit-keyframes menu_sticky {
    0%  {margin-top:-120px;opacity: 0;}
    50%  {margin-top: -64px;opacity: 0;}
    100% {margin-top: 0;opacity: 1;}
}
@keyframes menu_sticky {
    0%  {margin-top:-120px;opacity: 0;}
    50%  {margin-top: -64px;opacity: 0;}
    100% {margin-top: 0;opacity: 1;}
}
.main-menu-wrapper {position: relative; border-bottom: 1px solid #e8e8e8;}
.main-menu-wrapper .container {position: relative;}
.main-menu-wrapper .logo {margin-top: 10px; margin-bottom: 10px;}
.main-menu-wrapper .logo img{height: 100px; width: auto;}
.main-menu-wrapper .right-widget>ul>li {line-height: 108px;display: inline-block;}
.main-menu-wrapper .right-widget .cart-icon a {
  position: relative;
  color: #383838;
  font-size: 18px;
  margin-right: 12px;
}
.main-menu-wrapper .right-widget .cart-icon a span {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  font-size: 11px;
  font-weight: 600;
  color: #555555;
  top: 52px;
  right: -13px;
}

.main-menu-wrapper.fixed {
    position: fixed !important;
    width:100%;
    top:0;
    left:0;
    z-index: 999;
    background: #fff;
    padding-top: 0;
    -webkit-box-shadow: 0px 13px 35px -12px rgba(35,35,35,0.15);
    box-shadow: 0px 13px 35px -12px rgba(35,35,35,0.15);
    -webkit-animation: menu_sticky 0.7s ease-in-out;
    animation: menu_sticky 0.7s ease-in-out;
}

.fixed #mega-menu-wrapper .nav>li>a{
    padding-top: 30px !important;
    padding-bottom: 40px !important;
}

@media (min-width: 1px) and (max-width: 991px) {
    .fixed #mega-menu-wrapper .nav>li>a{
	padding-top: 0px !important;
	padding-bottom: 0px !important;
    }
}
.navbar-light .navbar-toggler {
    margin: 40px 0 !important;
    background: #31607d !important;
}

.main-menu-wrapper .logo .a {
    width: 80%;
    height: auto;
    max-width: 50px;
    object-fit: contain;
    margin: 0px;
}

.main-menu-wrapper.clearfix {
    background-color: silver;
}

#navbarNav {
    border-radius: 0;
    padding: 32px 0;
    z-index: 16;
}

@media (min-width: 1px) and (max-width: 991px) {
    #navbarNav {
	border-radius: 0;
	padding: 32px 0;
	z-index: 16;
	position: relative;
	top: 100px;
    }
}

.navbar.top-bar {
    borderer-radius: 0;
    padding: 16px 0;
    z-index: 16;
}
.navbar-toggler {
    border: 1px solid #fff;
    color: #fff;
    position: absolute;
    right: 21px;
}

#mega-menu-wrapper .nav>li>a:before {
  content: '.';
  position: absolute;
  right: -20px;
  bottom: 15px;
}

.top-bar a.navbar-brand {
    color: #fff;
    font-size: 26px;
    font-weight: 800;
    padding: 5px 0 0 10px;
    text-transform: uppercase;
}
.sps--blw.top-bar a.navbar-brand {
    color: #000;
}
.top-bar a.navbar-brand span {
    color: #0297ff;
}
.top-bar .nav-link {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 18px!important;
}
.sps--blw.top-bar .nav-link {
    color: #000
}
.top-bar .navbar-nav .nav-item {
    margin: 0
}
.top-bar .nav-link:hover, .top-bar .nav-item.active a {
    color: #fff;
    border-bottom: 2px solid #fff;
    border-radius: 0;
}

</style>

