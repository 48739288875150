<template>
  <div class="top-header">
    <div class="container">
      <div class="clearfix">
	<div class="float-left">
	  <ul class="left-widget clearfix">
	    <li><a href="#"><i class="fa fa-map-marker" aria-hidden="true"></i> Boston, Massachusetts, United States</a></li>
	    
	  </ul>
	</div>
	<div class="float-right">
	  <ul class="right-widget clearfix">
	    <li><a href="#"><i class="fa fa-envelope" aria-hidden="true"></i> brian@tenfactorgrowth.com</a></li>
	  </ul>
	</div>
      </div> <!-- /.clearfix -->
    </div> <!-- /.container -->
  </div> <!-- /.top-header -->
</template>

<script>
export default {
    name: 'MainHeader',
    components: {
    },
    methods: {
    }
}
</script>

<style scoped>
.theme-main-header .top-header {background: #31607d;}

.top-header .left-widget{ 
 margin: 0pt;
}

.top-header .left-widget a{ 
 color: rgba(255,255,255,0.8) !important;
 font-size: 15px !important;
}

.top-header .right-widget{ 
 color: rgba(255,255,255,0.8) !important;
 font-size: 15px !important;
 margin: 0pt;
}

.theme-main-header .top-header .left-widget .social-icon li:first-child {
    border-left: 1px solid rgba(255,255,255,0.12);
}

.theme-main-header .top-header .left-widget .social-icon li a {
  display: block;
  font-size: 15px;
  color: rgba(255,255,255,0.5);
}

.theme-main-header .top-header .left-widget>li {display: inline-block;line-height: 45px;vertical-align: middle;}

.theme-main-header .top-header .left-widget .social-icon li {
  float: left;
  width: 50px;
  text-align: center;
  border-right: 1px solid rgba(255,255,255,0.12);
}

.theme-main-header .top-header .right-widget>li {
    float:left;line-height: 45px;
}

.theme-main-header .top-header .right-widget>li a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: rgba(255,255,255,0.8);
  position: relative;
  padding: 0 25px 0 45px;
  display: block;
}

.theme-main-header .top-header .right-widget>li a i {
  position: absolute;
  line-height: 45px;
  top:0;
  left:20px;
  font-size: 14px;
}

.theme-main-header .top-header .right-widget>li.quote a {
  font-family: 'Poppins', sans-serif;
  padding: 0 28px;
  text-align: center;
  color: #010101;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

</style>

